.loop-users-list {
  &.loop-users-list-table {
    > * + * {
      border-top: 1px solid var(--light-ui-element);
    }
  }
}

.loop-user-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px 0;

  &.loop-user-item-clickable {
    padding: 8px 12px;
    cursor: pointer;

    &:hover {
      border-radius: 6px;
      background: var(--light-ui-element);
    }
  }

  .name-description {
    display: flex;
    flex-direction: column;
    gap: 2px;
  }

  .name {
    font-size: 15px;
    font-weight: 600;
  }

  .description {
    font-size: 12px;
    color: var(--medium-ui-element);
  }

  .remove {
    display: flex;
    margin-left: auto;
    cursor: pointer;
    color: var(--medium-ui-element);
    width: 32px;
    height: 32px;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    transition: var(--animation-speed);

    &:hover {
      background: var(--light-ui-element);
    }

    svg-comp {
      width: 16px;
      height: 16px;
      color: inherit;
      transition: var(--animation-speed);
    }
  }
}
