a,
a:visited {
  text-decoration: none;
  color: var(--strong-ui-element);
  cursor: pointer;

  // Current active route
  &.router-link-active {
    color: var(--unread-color);
  }
}

.comment-content a {
  color: var(--unread-color);
  user-select: text;
  text-decoration: underline;
}
