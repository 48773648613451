// Font import & sizes
// @import url(https://fonts.googleapis.com/css?family=Roboto:400,500,700&subset=latin,latin-ext);

//$font-family: 'Roboto', Arial, sans-serif;
$font-family: var(--loop-font-default);

$font-weight-normal: 400;
$font-weight-bold: bold;

$font-size-extra-small: 10px;
$font-size-small: 12px;
$font-size-normal: 14px;
$font-size-large: 15px;

$font-size-h1: 20px;
$font-size-h2: 18px;
$font-size-h3: 16px;
