@import '../../scss/reference';

main {
  display: flex;
  height: 100%;
  flex-direction: column;

  &.hasNotice {
    height: calc(100% - 50px);
  }
}

.sidebar {
  position: relative;
}

.sidebar,
.sidebar-extra {
  height: 100%;
  min-width: 250px;
  max-width: 450px;
  display: flex;
  flex-direction: column;
  position: relative;

  &.full-width {
    width: 100%;
    max-width: 100%;
  }
}

.collapse-sidebar .sidebar {
  max-width: 0;
  min-width: 0;
}

.sidebar.no-style,
.main.no-style,
.channel-main-wrapper .main {
  background: none;
  border: none;
  box-shadow: none;
  overflow: visible;
  padding: 0px;
  margin-top: 0px !important;
  height: 100% !important;
}

.main-content {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  height: calc(100% - 50px);
}

.content {
  flex: 1;
  display: flex;
  overflow: hidden;
  height: 100%;
  flex-direction: column;
  background-color: var(--background-light);
}

.main {
  flex: 1 1 0;
  overflow: hidden;
  min-width: 370px;
  &.no-margin-side {
    margin-right: 0;
    margin-left: 0;
    margin-bottom: 0;
    height: 100%;
  }
  &.no-margin {
    margin: 0;
    height: calc(100%);
  }
  &.no-bckg {
    background-color: unset;
    box-shadow: unset;
  }
  &.relative {
    position: relative;
  }
}

.loopbar {
  min-width: 410px;
  max-width: 500px;
  flex-grow: 1;
  flex-shrink: 5;
  flex-basis: 475px;
  &.no-margin {
    margin: 0;
    height: calc(100%);
  }
}

.sidebar-title {
  font-size: 24px;
  font-weight: 300;
  color: var(--strong-ui-element);
  padding: 16px 30px 0 30px;
  width: 100%;
  min-height: 90px;
  height: 90px;
  text-align: left;
  border-bottom: var(--light-ui-element) 1px solid;
  &.no-padding {
    padding: 0px;
  }
  &.no-border {
    border-bottom: none;
  }
  &.with-tabs {
    min-height: 32px;
    height: 32px;
    line-height: 30px;
    padding: 0px 30px;
  }
}

.white-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  background: var(--background-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-right: 1px solid var(--light-ui-element);
}

.white-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background: var(--background-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .channel-main-wrapper & {
    box-shadow: none;
  }
}
.white-full {
  border-radius: 4px;
  box-shadow: 0 0px 12px 0 rgba(0, 0, 0, 0.1);
  background: var(--background-light);
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.full-height {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.top {
  flex-shrink: 0;
  position: relative;
  z-index: 2;
}
.bottom {
  display: flex;
  height: 100%;
  overflow: hidden;
  flex-direction: row;
}

.no-margin-top {
  &.loopbar,
  &.main {
    margin-top: 0px;
    height: 100%;
    padding: 0px;
  }
}

.account-menu {
  padding-top: 46px;
}
.account-menu {
  padding-bottom: 8px;
}
.darwin-margin {
  margin-top: 16px;

  .darwin & {
    margin-top: 26px;
  }
}
.full-width {
  width: 100%;
}

.scroller {
  // overflow-y: auto;
  // overflow-x: hidden;
  height: 100%;
  position: relative;
}

.editor .tag,
.froala-editor .tag {
  color: var(--unread-color);
  background-color: rgba(49, 182, 220, 0.15);
  &:before {
    content: '@';
  }
}

.chat-bubble .comment span {
  .tag {
    color: var(--unread-color);
  }
  .tag .tag {
    &:before {
      content: '';
    }
  }
}

// TODO, should not be here.
.comment-item:last-of-type {
  border-bottom: none;
  .post-comment {
    border-bottom: none;
  }
}

.folder-browser-outlet {
  width: 100%;
}
