@import '../reference';

.divider {
  position: relative;
  text-align: center;
  background: var(--background-light);
  overflow: hidden;
  &:before {
    content: '';
    position: absolute;
    top: 11px;
    left: -50px;
    right: -50px;
    border-top: 1px solid var(--medium-ui-element);
  }
  .text {
    position: relative;
    font-size: 12.5px;
    font-weight: $font-weight-bold;
    color: var(--medium-ui-element);
    padding: 0 15px;
    background: var(--background-light);
  }
}
