@import '../../scss/reference';

.more-button,
.c-more-button {
  cursor: pointer;
  width: 40px;
  height: 16px;
  border-radius: 2px;
  border: solid 1px #dcdadf;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  color: var(--medium-ui-element);
  color: transparent;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    width: 4px;
    height: 4px;
    background: var(--medium-ui-element);
    border-radius: 50%;
    left: calc(50% - 1.5px);
    top: 6px;
    box-shadow:
      8px 0 0 var(--medium-ui-element),
      -8px 0 0 var(--medium-ui-element);
  }

  &.hide {
    display: none;
  }

  &:hover {
    background: rgba(0, 206, 186, 0.2);
  }
}

.more-content {
  display: none;

  &.show {
    display: block;
  }
}
