////////////////
// Simplebar
////////////////
[data-simplebar] {
  position: relative;
  z-index: 0;
  overflow: hidden;
  -webkit-overflow-scrolling: touch;
  /* Trigger native scrolling for mobile, if not supported, plugin is used. */

  &::-webkit-scrollbar,
  > ::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  > ::-webkit-scrollbar-thumb,
  > ::-webkit-scrollbar-track,
  &::-webkit-scrollbar-track {
    visibility: hidden;
  }
  .scrollbar {
    &::-webkit-scrollbar,
    ::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-thumb,
    ::-webkit-scrollbar-track,
    &::-webkit-scrollbar-track {
      visibility: visible;
    }
  }
}

[data-simplebar='init'] {
  display: flex;
}

.simplebar-scroll-content {
  overflow-x: hidden;
  overflow-y: scroll;
  min-width: 100%;
  box-sizing: content-box;
  margin-bottom: 0px !important;
}

.simplebar-content {
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  min-height: 100%;
  flex-direction: column;
  display: flex;
  position: relative;
  .library-dialog-list & {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;
  }
  .loopin-original & {
    justify-content: flex-end;
  }
}

.simplebar-track {
  z-index: 100;
  position: absolute;
  right: 0;
  bottom: 2px;
  width: 11px;
  &:hover {
    width: 16px;
  }
}

.simplebar-scrollbar {
  visibility: inherit !important;
  position: absolute;
  right: 2px;
  border-radius: 3px;
  min-height: 10px;
  width: 7px;
  opacity: 0;
  transition:
    opacity 0.2s linear,
    width 200ms ease;
  background: var(--strong-ui-element);
  background-clip: padding-box;

  .main-menu & {
    background: rgb(251, 251, 251);
  }

  *[simple-bar-directive]:hover & {
    opacity: 0.25;
  }
}

.simplebar-track:hover .simplebar-scrollbar,
.simplebar-scrollbar:active,
.simplebar-scrollbar:hover {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.3;
  transition:
    width 200ms ease-in-out,
    border-radius 200ms ease-in-out;
  width: 12px;
  border-radius: 6px;
}

.hide-horizontal-simplebar {
  .simplebar-track.horizontal {
    display: none;
  }
}
.simplebar-track.horizontal {
  left: 0;
  width: auto;
  height: 11px;
}

.simplebar-track.vertical {
  top: 0;
}

.horizontal.simplebar-track .simplebar-scrollbar {
  right: auto;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

.comments {
  position: relative;
  margin-right: -15px !important;
  .simplebar-scroll-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
}

.canned-response-dropdown {
  .simplebar-scroll-content {
    margin-bottom: -10px !important;
    .simplebar-content {
      padding-bottom: 10px !important;
    }
  }
}

.drop-down-menu.is-on-composer {
  .simplebar-content {
    padding-bottom: 0 !important;
  }
}

// Prevent from scrolling if comment actions had dropdown/emojis open
.comment-actions-active {
  .simplebar-scroll-content {
    overflow-y: hidden !important;

    .simplebar-content {
      margin-right: 0 !important;
    }
  }
}
