//////////////
// Variables
//////////////
$scroll-px: 5px;
$scroll-wide-px: 10px;

////////////
// NATIVE
////////////
// The entire scrollbar.
::-webkit-scrollbar {
  width: $scroll-px;
  height: $scroll-px;
  background: transparent;
  cursor: pointer !important;
}

// The track (progress bar) of the scrollbar.
::-webkit-scrollbar-track {
  width: $scroll-px;
  height: $scroll-px;
}

::-webkit-scrollbar-track:horizontal {
  background: transparent;
}

// Corner where scrolltracks meet
::-webkit-scrollbar-corner {
  background: var(--light-ui-element);
}

.menu-scrollbar::-webkit-scrollbar-track {
  background: var(--primary-color-light);
}

.menu-scrollbar::-webkit-scrollbar-corner {
  background: var(--primary-color-light);
}

// The draggable scrolling handle.
::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 10px;
}

// On scroll content hover
:hover::-webkit-scrollbar-thumb {
  background: #b4b4b454;
}

// On scroll thumb hover
::-webkit-scrollbar-thumb:hover {
  background: #b4b4b496;
}

// Make scrollbar wider on hover, so user has bigger aria to drag
.scrollbar-wide::-webkit-scrollbar {
  width: $scroll-wide-px;
}

.scroll-wrapper-global {
  cursor: pointer !important;
  overflow-y: overlay;
}

.disable-horizontal-scroll {
  overflow-x: hidden;
}

////////////////////
// Mozilla support
////////////////////
@-moz-document url-prefix() {
  * {
    scrollbar-width: thin;
  }
}
