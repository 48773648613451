@import '../reference';

.title-small {
  font-size: $font-size-normal;
  color: var(--medium-ui-element);
}

.heading-small {
  color: var(--medium-ui-element);
  line-height: 30px;
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;

  .number {
    font-size: 13px;
    font-weight: normal;
    color: var(--medium-ui-element);
    text-transform: none;
  }
}
.notified {
  .sidebar {
    top: 32px;
  }
}
