input[disabled] {
  background: none;
}

// Enable inputs in Safari
input,
input:before,
input:after {
  -webkit-user-select: initial !important;
  -khtml-user-select: initial !important;
  -moz-user-select: initial !important;
  -ms-user-select: initial !important;
  user-select: initial !important;
}

input .input-square {
  border-radius: 5px;
  border: 1px solid var(--medium-ui-element);
}
