.action-error {
  font-size: 12px;
  color: var(--danger);
  margin-bottom: 12px;
  overflow-wrap: break-word;
  margin-top: auto;
  text-align: center;
  max-width: 140px;
  max-height: 144px;
  overflow: hidden;
}
