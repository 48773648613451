@import '@angular/cdk/overlay-prebuilt.css';

@import '~shared/assets/styles/helpers';
@import '~shared/assets/styles/fonts';
@import '~shared/assets/styles/font-helpers';
@import '~shared/assets/styles/variables';
@import '~shared/assets/styles/colors';
@import '~shared/assets/styles/app-theme';
@import '~shared/assets/styles/color-helpers';

// Settings
@import 'dta/ui/scss/settings/base-path';
@import 'dta/ui/scss/settings/typography';

// Tools
@import 'dta/ui/scss/tools/keyframes';

// Generic
@import 'dta/ui/scss/generic/border-box';
@import 'dta/ui/scss/generic/normailze';
@import 'dta/ui/scss/generic/clearfix';

//Elements
@import 'dta/ui/scss/elements/body';
@import 'dta/ui/scss/elements/lists';
@import 'dta/ui/scss/elements/links';
@import 'dta/ui/scss/elements/buttons';
@import 'dta/ui/scss/elements/html-elements';
@import 'dta/ui/scss/elements/input-fields';
@import 'dta/ui/scss/elements/webkit-scrollbar-custom';
@import 'dta/ui/scss/elements/simplebar';
@import 'dta/ui/scss/elements/placeholder';
//@import "dta/ui/scss/elements/icons";
@import 'dta/ui/scss/elements/checkboxes';
@import 'dta/ui/scss/elements/errors';

// Objects
@import 'dta/ui/scss/objects/o-flex-row';
@import 'dta/ui/scss/objects/o-block';
@import 'dta/ui/scss/objects/o-height.scss';
@import 'dta/ui/scss/objects/o-scrollbar-container.scss';

// Components
@import 'dta/ui/scss/components/comment';
@import 'dta/ui/scss/components/page-layout';
@import 'dta/ui/scss/components/page-title';
//@import "dta/ui/scss/components/page-library";
@import 'dta/ui/scss/components/notification-badge';
@import 'dta/ui/scss/components/avatars';
@import 'dta/ui/scss/components/headings';
@import 'dta/ui/scss/components/file-dropzone';
@import 'dta/ui/scss/components/c-menu-item';
@import 'dta/ui/scss/components/buttons';
@import 'dta/ui/scss/components/collapse-button';
@import 'dta/ui/scss/components/dropdowns';
@import 'dta/ui/scss/components/divider';
@import 'dta/ui/scss/components/svg';
@import 'dta/ui/scss/components/add-file-button';
@import 'dta/ui/scss/components/board-edit';
@import 'dta/ui/scss/components/modal-zindex';
@import 'dta/ui/scss/components/froala-override';
@import 'dta/ui/scss/components/middle-item';

// External stuff
@import 'dta/ui/scss/external/ps-scrollbar';
@import 'dta/ui/scss/external/user-flow';

// Alterations
@import 'dta/ui/scss/alterations/helpers';
@import 'dta/ui/scss/alterations/font-styles';

// Stuff bellow should be placed in appropriate files/folders!

// FORM Elements - common
.form-row {
  border-bottom: solid 1px var(--light-ui-element);
}

::-ms-clear {
  display: none;
}

// Solving safari problem
.editor .tag {
  -webkit-user-modify: read;
  -webkit-user-select: text;
  user-select: text;
}

img.emoji {
  height: 1em;
  width: 1em;
  margin: 0 0.05em 0 0.1em;
  vertical-align: -0.1em;
}
.icon {
  display: inline-block;
}

.fade-in {
  animation: fade-in 1s 1 ease-in-out;
}

.recipients-text-underline {
  border-bottom: 1px solid var(--medium-ui-element);
  margin-left: 6px;
}

// Dropzone
.dropzone {
  display: none;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 5px;
  z-index: 200;
  text-align: center;
  position: absolute;
  border-radius: 6px;
  border: dashed 2px var(--highlight-color);
  background-color: var(--light-overlay);
  color: var(--medium-ui-element);
  font-size: 15px;
  font-weight: 700;
  transition: transform 120ms ease;

  * {
    pointer-events: none;
  }

  &.show {
    display: block;
    z-index: 100;
  }

  span {
    color: var(--medium-ui-element);
  }
}

.dropzone-icon {
  svg-comp {
    height: 42px;
    width: 30px;
    padding: 11px 17px;
    box-sizing: content-box;
    background: var(--light-ui-element);
    border-radius: 60%;
    margin-bottom: 10px;
    animation: pop-out 2s ease-in-out infinite;
    svg {
      color: var(--medium-ui-element);
      fill: var(--medium-ui-element);
    }
  }
}

.dragging {
  .modal-dialog {
    pointer-events: none;
  }
}

.card-layout {
  position: relative;
  max-width: 850px;
  //min-height: 400px;
  margin: 30px auto;
  background: var(--background-light);
  padding: 50px 20px 50px 20px;
  border-radius: 6px;

  .title {
    font-size: 12px;
    color: var(--medium-ui-element);
    line-height: 30px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    border-bottom: 1px solid var(--light-ui-element);
    font-weight: 700;
  }
}

.section-content {
  margin-left: 20px;
  margin-right: 20px;
}

.version-info {
  font-size: 10px;
  text-align: center;
  line-height: 20px !important;
  padding-bottom: 20px;
  color: var(--medium-ui-element);

  .made-with-love {
    font-size: 12px;
    line-height: 20px !important;

    span.red {
      font-size: 10px;
      color: #fa3264 !important;
    }

    span.send-feedback {
      color: var(--unread-color);
      cursor: pointer;
      display: block;
    }
  }
}

.confirm-modal {
  text-align: center;
  cursor: default;

  .popup-logo svg-comp {
    color: var(--unread-color);
    width: 50px;
    height: 36px;
  }

  .modal-dialog {
    max-width: 475px;
    min-height: 175px;
  }

  .modal-body {
    padding: 20px;
  }

  .title {
    font-size: 20px;
    color: black;
    padding: 20px 40px 20px 40px;
    text-align: center;
    line-height: 20px;
    font-weight: $font-weight-bold;
  }

  .text {
    font-size: 15px;
    color: var(--medium-ui-element);
  }

  footer {
    justify-content: center;
    height: auto;
    padding: 5px 24px 24px;
  }
}

.confirm-draft-dialog-modal .modal-dialog {
  max-width: 475px;
  background: transparent;
}

.confirm-leave-dialog-modal .modal-dialog {
  max-width: 520px;
  background: transparent;
}

.card-modal .modal-dialog {
  height: auto;
  max-height: none;
}
.card-modal {
  .feed-discussion.opened {
    margin: 0px auto !important;
  }
}

.basic-input {
  outline: none;
  resize: none;
  border: 1px solid var(--medium-ui-element);
  border-radius: 3px;
  height: 30px;
  padding: 0 5px;
  transition: border-color 0.2s ease-in-out;
}

.input-subject input {
  width: 100%;
  display: block;
}

app {
  height: 100%;
}

main-app {
  flex: 1 1;
  overflow: hidden;
}

#drag {
  height: 38px;
  -webkit-app-region: drag;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
}

.highlight {
  background-color: rgba(176, 233, 249, 0.6);
  display: inline !important;
}

.ngx-emoji-search {
  height: 30px !important;
  color: var(--strong-ui-element) !important;
  border-color: var(--light-ui-element) !important;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.emailBody p {
  margin: 0;
}

.c-menu-item.isOnQuickJump .c-menu-item__icon {
  width: 40px;
  height: 40px;
}

.unfurl iframe,
.unfurl webview {
  width: 100%;
  height: 100%;
  position: absolute;
  border: none;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99999;
  }
}

button[data-cmd='linkList'] {
  display: none !important;
}

.extended-onboarding-modal {
  img {
    max-width: 100%;
  }
}

/////////////
// LOAD MORE
/////////////

.load-more-complete {
  display: flex;
  justify-content: space-around;
  height: 64px;
  align-items: center;
  font-size: 15px;
  color: var(--medium-ui-element);
}

///////////////////
// LOADER as class
///////////////////

.loop-loader {
  color: var(--background-light);
  font-size: 90px;
  text-indent: -9999em;
  overflow: hidden;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin: 72px auto;
  position: relative;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
  animation:
    load6 1.7s infinite ease,
    round 1.7s infinite ease;
}
@-webkit-keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}
@keyframes load6 {
  0% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  5%,
  95% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
  10%,
  59% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.087em -0.825em 0 -0.42em,
      -0.173em -0.812em 0 -0.44em,
      -0.256em -0.789em 0 -0.46em,
      -0.297em -0.775em 0 -0.477em;
  }
  20% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.338em -0.758em 0 -0.42em,
      -0.555em -0.617em 0 -0.44em,
      -0.671em -0.488em 0 -0.46em,
      -0.749em -0.34em 0 -0.477em;
  }
  38% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      -0.377em -0.74em 0 -0.42em,
      -0.645em -0.522em 0 -0.44em,
      -0.775em -0.297em 0 -0.46em,
      -0.82em -0.09em 0 -0.477em;
  }
  100% {
    box-shadow:
      0 -0.83em 0 -0.4em,
      0 -0.83em 0 -0.42em,
      0 -0.83em 0 -0.44em,
      0 -0.83em 0 -0.46em,
      0 -0.83em 0 -0.477em;
  }
}
@-webkit-keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes round {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes pop-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

///////////
// Scroll
///////////
.scroll-wrapper {
  height: 100%;
  overflow-y: scroll;
}
