@-webkit-keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em var(--unread-color);
  }
  40% {
    box-shadow: 0 1.5em 0 0 var(--unread-color);
  }
}
@keyframes load7 {
  0%,
  80%,
  100% {
    box-shadow: 0 1.5em 0 -1.3em var(--unread-color);
  }
  40% {
    box-shadow: 0 1.5em 0 0 var(--unread-color);
  }
}

@keyframes pop-in-successful {
  0% {
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
  }
  70% {
    visibility: visible;
    opacity: 1;
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pop-in-out {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(0, 0);
  }
  5% {
    opacity: 0.5;
    visibility: visible;
    transform: scale(1.1, 1.1);
  }
  10%,
  90% {
    opacity: 1;
    visibility: visible;
    transform: scale(1, 1);
  }
  95% {
    opacity: 0.75;
    visibility: visible;
    transform: scale(1.1, 1.1);
  }
  100% {
    opacity: 0;
    transform: scale(0, 0);
    visibility: hidden;
  }
}
@keyframes scale-in-out {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes download {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0);
  }
}
@keyframes slide-up-10 {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes slide-down-20 {
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
  50% {
    opacity: 0.9;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fade-in-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-delay {
  0% {
    opacity: 0;
  }
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-chat-scarlett {
  0%,
  70% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in-chat-temporal {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 0.4;
    transform: translateY(0);
  }
}
@keyframes pulse {
  0% {
    transform: scale(0.5);
    opacity: 0;
  }
  25% {
    opacity: 1;
  }
  75% {
    opacity: 0.25;
  }
  90% {
    opacity: 0;
  }
  100% {
    transform: scale(1.8);
    opacity: 0;
  }
}
@keyframes pulse-opacity {
  0% {
    // transform: scale(0.9);
    opacity: 0.4;
  }
  50% {
    // transform: scale(1);
    opacity: 1;
  }
  100% {
    // transform: scale(0.9);
    opacity: 0.4;
  }
}

@keyframes pulse {
  0% {
    transform: scale(0.9);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.9);
    opacity: 0.7;
  }
}

@keyframes shadow-fade-out {
  0% {
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.42) !important;
  }
  100% {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.3);
  }
}
@keyframes follow-up-percentage {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(3);
  }
  60% {
    opacity: 1;
    visibility: visible;
    transform: scale(0.75);
  }
  70% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes pop-in {
  0% {
    opacity: 0;
    visibility: hidden;
    transform: scale(3);
  }
  60% {
    opacity: 1;
    visibility: visible;
    transform: scale(0.75);
  }
  70% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes follow-up-elements {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-typing {
  0% {
    opacity: 0.1;
  }
  35% {
    opacity: 1;
  }
  50% {
    opacity: 1;
    transform: translateY(-5px);
  }
  80% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
    transform: translateY(0);
  }
}
@keyframes rotateY {
  from {
    transform: rotateY(0deg);
  }
  to {
    transform: rotateY(360deg);
  }
}
@-webkit-keyframes fade-dot {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-moz-keyframes fade-dot {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@-webkit-keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-moz-keyframes circle {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes circle {
  0% {
    transform: rotate(0deg);
  }
  75% {
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes shake {
  0% {
    transform: rotate(8deg);
  }
  20% {
    transform: rotate(-8deg);
  }
  60% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes shake {
  0% {
    transform: rotate(15deg);
  }
  20% {
    transform: rotate(-15deg);
  }
  60% {
    transform: rotate(5deg);
  }
  100% {
    transform: rotate(0);
  }
}
@keyframes popup {
  0% {
    transform: scale(0.7);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes eye-blink {
  0% {
    opacity: 1;
    visibility: visible;
  }
  50% {
    opacity: 1;
    visibility: visible;
  }
  60% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
@keyframes ripple {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1);
  }
  90%,
  100% {
    opacity: 0;
    transform: scale(1);
  }
}
@keyframes bounceInDown {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 1, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, -15px, 0) scaleY(0.9);
  }

  60% {
    transform: translate3d(0, 5px, 0);
  }

  75% {
    transform: translate3d(0, -0px, 0);
  }

  // 90% {
  //   transform: translate3d(0, 5px, 0);
  // }

  to {
    opacity: 1;
    transform: none;
  }
}
@keyframes bounceInUp {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 1, 1);
  }

  0% {
    opacity: 0;
    transform: translate3d(0, 15px, 0) scaleY(0.7);
  }

  60% {
    transform: translate3d(0, -5px, 0);
  }

  75% {
    transform: translate3d(0, 0px, 0);
  }

  // 90% {
  //   transform: translate3d(0, 5px, 0);
  // }

  to {
    opacity: 1;
    transform: none;
  }
}
