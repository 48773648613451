@mixin ellipsis($width) {
  white-space: nowrap;
  width: $width;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  border-radius: $radius;
}

@mixin rotate($deg) {
  -webkit-transform: rotate($deg);
  transform: rotate($deg);
}

@mixin circle($diameter) {
  width: $diameter;
  height: $diameter;
  border-radius: $diameter * 0.5;
}

@mixin translateY($val) {
  -webkit-transform: translateY($val);
  transform: translateY($val);
}

@mixin translateX($val) {
  -webkit-transform: translateX($val);
  transform: translateX($val);
}

@mixin min-height($screen) {
  @media (min-height: $screen+'px') {
    @content;
  }
}
