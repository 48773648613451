:root {
  --font-weight-default: 400;
  --font-weight-semibold: 500;
  --font-weight-bold: 600;

  --font-extra-size-small: 12px;
  --font-size-small: 12px;
  --font-size-default: 14px;
  --font-size-new-default: 13px;
  --font-size-large: 16px;
  --font-size-x-large: 20px;

  --border-small: 1px solid;
  --border-large: 2px solid;

  --border-radius-normal: 4px;
  --border-radius-large: 8px;
  --border-radius-extra-large: 16px;

  --theme-spacing: 16px;
  --theme-spacing-half: 8px;
  --theme-spacing-quarter: 4px;

  --theme-border-radius: var(--border-radius-normal);

  --line-height-title: 1.25;
  --line-height-text: 1.45;

  --theme-delimiter-color: var(--light-ui-element);

  --backdrop-filter: none;
  @supports ((-webkit-backdrop-filter: blur(20px) saturate(150%)) or (backdrop-filter: blur(20px) saturate(150%))) {
    --backdrop-filter: blur(20px) saturate(150%);
  }
}
