@import '../reference';

$button-size: 30px;

button {
  &.btn {
    height: $button-size;
    padding-left: $button-size;
    padding-right: $button-size;
    @include border-radius($button-size * 0.5);
  }

  &.btn--outlined {
    border: 1px solid var(--medium-ui-element);
    font-size: 12px;
    color: var(--medium-ui-element);
  }

  &.posting {
    width: $button-size;
    height: $button-size;
    min-width: 0;
    padding: 0 !important;
    background: transparent !important;
    text-indent: 100%;
    white-space: nowrap;
    overflow: hidden;
    animation: circle infinite 0.75s linear;
    border: 2px solid var(--unread-color);
    border-top-color: transparent;
    border-radius: 100%;
  }
}

.button {
  cursor: pointer;
  text-decoration: none;
  font-weight: 700;
  padding: 0 30px;
  outline: none;
  font-size: 12px;
  min-width: 80px;
  border-radius: 6px;
  line-height: 30px;
  text-align: center;
  transition: var(--animation-speed);
  &.min-130 {
    min-width: 130px;
  }
  &.super-rounded {
    border-radius: 14px;
  }

  &.cancel {
    border-radius: 21px;
    border: solid 1px var(--medium-ui-element);
    letter-spacing: -0.09px;
    text-align: center;
    color: var(--medium-ui-element);
  }

  &.primary-green {
    background-color: var(--unread-color-80);
    color: var(--background-light);
    height: 32px;
    border-radius: 16px;
    &:hover,
    &:focus,
    &.selected {
      background: var(--unread-color);
    }

    &.disabled,
    &:disabled {
      color: var(--medium-ui-element);
      background-color: var(--light-ui-element);
      cursor: unset;
    }
  }
  &.danger {
    background-color: var(--danger-80);
    color: var(--background-light);
    height: 32px;
    border-radius: 16px;
    &:hover,
    &:focus,
    &.selected {
      background: var(--danger);
    }
    &:disabled {
      color: var(--medium-ui-element);
      background-color: var(--light-ui-element);
    }
  }
  &.primary-white {
    background: transparent;
    color: var(--medium-ui-element);
    border: 1px solid var(--medium-ui-element);
    &:hover {
      color: var(--medium-ui-element);
      background: var(--light-ui-element);
      border: 1px solid var(--medium-ui-element);
    }
    &:disabled {
      background-color: var(--light-ui-element);
      border: 1px solid var(--unread-color);
      color: var(--medium-ui-element);
    }
  }
  &.primary-white-green {
    background: transparent;
    color: var(--unread-color-80);
    border: 1px solid var(--unread-color-80);
    &:hover {
      color: var(--unread-color);
      border: 1px solid var(--unread-color);
    }
    &:disabled {
      background-color: var(--light-ui-element);
      border: 1px solid var(--unread-color);
      color: var(--medium-ui-element);
    }
  }
  &.reverse-white {
    background: var(--background-light);
    color: var(--unread-color);
    border: 1px solid while;
    &:hover {
      color: var(--unread-color);
      background: var(--light-ui-element);
      border: 1px solid var(--light-ui-element);
    }
    &:disabled {
      background-color: var(--unread-color);
      border-color: var(--unread-color);
      color: var(--unread-color);
    }
  }
  &.reverse-green {
    background: transparent;
    color: var(--background-light);
    border: 1px solid white;
    &:hover {
      color: var(--background-light);
      background: var(--unread-color);
      border: 1px solid #b2f0ea;
    }
    &:disabled {
      background-color: var(--unread-color);
      border: 1px solid var(--unread-color);
      color: var(--unread-color);
    }
  }
}

.btn {
  cursor: pointer;
  text-decoration: none;
  padding: 0 30px;
  outline: none;
  border: none;
  height: 30px;
  font-size: 12px;
  min-width: 80px;
  border-radius: 19px;

  &.btn-primary {
    background: var(--unread-color);
    color: var(--background-light);
  }
  &:disabled {
    background: #f0f0f040;
    color: var(--medium-ui-element);
    pointer-events: none;
  }
  &.modern-green {
    background: var(--unread-color);
    color: var(--background-light);
    padding: 0 30px;
    will-change: background;

    &:hover:not(:disabled):not(.loading) {
      background: linear-gradient(to bottom, #00cee8 0, var(--unread-color) 100%);
      transition: background 0.2s ease-in-out;
    }
  }

  &.reply-button {
    margin: 4px 0;
  }
  &.empty {
    border: none;
    opacity: 0.5;
    &:hover {
      opacity: 1;
      transition: opacity 0.1s ease-in-out;
    }
  }
  &.login {
    display: block;
    margin: 15px auto 0px auto;
    padding: 0;
    min-width: 0;
    width: 210px;
    height: 40px;
    color: rgba(0, 0, 0, 0.54);
    border-radius: 20px;
    margin-top: 20px;
    font-weight: $font-weight-normal;
    overflow: hidden;
    transition: background-color 0.1s ease-in-out;
    text-align: left;
    box-shadow:
      0 2px 2px 0 rgba(0, 0, 0, 0.24),
      0 0 2px 0 rgba(0, 0, 0, 0.12);
    display: flex;
    align-items: center;

    &.google {
      background: #ffffff;
      margin-bottom: 7px;
      transition: var(--animation-speed);
      &:hover {
        transform: translate3D(2px, 2px, 0px);
      }
    }
    .icon {
      border-radius: 1px;
      float: left;
      color: #000000;
      display: flex;
      align-items: center;
      margin-left: 8px;
    }
    svg-comp {
      width: 30px;
      height: 30px;
      overflow: hidden;
      display: inline-block;
      vertical-align: middle;
    }
    svg-comp:not(.svg-no-modal-color) svg-comp {
      color: #0067a5;
    }
    .text {
      font-family: $font-family;
      vertical-align: middle;
      line-height: 40px;
      font-size: 14px;
      font-weight: 500;
      letter-spacing: 0.2px;
      margin-left: 8px;
    }
    &.exchange {
      background: var(--background-light);

      &:hover {
        background: var(--chat-bubble) 44;
      }
      .full-size {
        svg-comp {
          width: 215px;
          margin: 8px 0 2px -51px;
        }
      }
    }
    &.outlook {
      background: var(--background-light);
      height: 40px;
      padding-left: 33px;

      &:hover {
        background: var(--chat-bubble) 44;
      }

      .full-size {
        svg-comp {
          width: 150px;
        }
      }
    }
    &.office365 {
      background: var(--background-light);
      height: 40px;
      padding: 0px 0px 8px 49px;

      &:hover {
        background: var(--chat-bubble) 44;
      }

      .full-size {
        svg-comp {
          width: 150px;
        }
      }
    }
  }

  &.btn-modern-empty {
    border: 1px solid var(--medium-ui-element);
    border-radius: 15px;
    padding: 5px 10px;
    &:hover:not(:disabled):not(.loading) {
      color: var(--medium-ui-element);
      border-color: var(--medium-ui-element);
    }
  }
  &.btn-modern-green {
    background: var(--unread-color);
    color: var(--background-light);
    padding: 0 30px;
    will-change: background;
    width: 120px;
    height: 30px;
    &:hover:not(:disabled):not(.loading) {
      background: linear-gradient(to bottom, #00cee8 0, var(--unread-color) 100%);
      transition: background 0.2s ease-in-out;
    }
    &:disabled {
      background: #f0f0f0;
      color: var(--medium-ui-element);
      pointer-events: none;
      &:hover {
        background: #f0f0f0;
        color: var(--medium-ui-element);
        pointer-events: none;
      }
    }
  }
}
