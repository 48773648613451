@import '../reference';

::-webkit-input-placeholder {
  color: var(--medium-ui-element);
}

:-moz-placeholder {
  color: var(--medium-ui-element);
}

::-moz-placeholder {
  color: var(--medium-ui-element);
}

:-ms-input-placeholder {
  color: var(--medium-ui-element);
}
