@import '../reference';

$badge-size: 16px;

.c-notification-badge {
  cursor: pointer;
  background: var(--unread-color);
  color: var(--background-light);
  font-size: 10px;
  text-align: center;
  min-width: $badge-size;
  padding-left: 3px;
  padding-right: 3px;
  height: $badge-size;
  vertical-align: middle;
  border-radius: $badge-size * 0.5;
  line-height: $badge-size;
  display: inline-block;

  i {
    font-style: normal;
    display: block;
    text-align: center;
    padding: 2px 0;
    line-height: 11px;
    font-size: 10px;
  }
}
