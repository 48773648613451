.font-default {
  font-size: var(--font-size-default);
}
.font-large {
  font-size: var(--font-size-large);
}
.font-new-default {
  font-size: var(--font-size-new-default);
}
.font-small {
  font-size: var(--font-size-small);
}
.font-strong {
  color: var(--strong-ui-element);
}
.font-medium {
  color: var(--medium-ui-element);
}
