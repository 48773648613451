@import '../reference';

// Perfect scrollbar
.ps-scrollbar-x-rail {
  height: 5px;
  display: none;
  position: absolute;
  border-radius: 4px;
  opacity: 0;
  transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  bottom: 3px;
}
.ps-scrollbar-y-rail {
  display: block;
  position: absolute;
  border-radius: 4px;
  opacity: 1;
  background: var(--background-light);
  transition:
    background-color 0.2s linear,
    opacity 0.2s linear;
  right: 0;
  width: 5px;
  z-index: 2;
  background: none;
}
.ps-scrollbar-y {
  position: absolute;
  right: 0;
  transition: background-color 0.2s linear;
  background: var(--medium-ui-element);
  width: 5px;
  border-radius: 0;
}
