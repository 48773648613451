*:not(.froala-new):not(.froala-new *) {
  box-sizing: border-box;
}

*:not(.froala-new):not(.froala-new *) {
  user-select: none;
}

.selectable {
  user-select: text !important;
}

.hidden-selection {
  display: inline-flex;
  opacity: 0;
  width: 0px;
  height: 0px;
  margin: 0px;
  &.new-line-after {
    display: block;
  }
}
