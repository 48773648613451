.file-dropzone {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  margin: 5px;
  z-index: 200;
  text-align: center;
  border-radius: 6px;
  border: dashed 2px var(--medium-ui-element);
  background-color: var(--light-overlay);
  color: var(--medium-ui-element);
  font-size: 15px;
  font-weight: 700;
  transition: transform 120ms ease;
  // just for decoration, no dropping handling
  pointer-events: none;

  &.composer {
    margin: 5px 25px;
    font-size: 18px;
  }

  &.reply {
    margin: 5px 0;
  }

  * {
    pointer-events: none;
  }

  &.show {
    display: block;
  }

  .dropzone-icon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;

    svg-comp {
      display: inline-block;
      height: 42px;
      width: 30px;
      padding: 11px 17px;
      box-sizing: content-box;
      background: var(--light-ui-element);
      border-radius: 60%;
      margin-bottom: 10px;
      animation: pop-out 2s ease-in-out infinite;

      svg {
        color: var(--medium-ui-element);
        fill: var(--medium-ui-element);
      }
    }
  }
}
