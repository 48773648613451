@import '../reference';
.c-menu-item {
  display: -webkit-flex;
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
  cursor: pointer;
  padding: 10px 13px 10px 14px;
  font-weight: 400;
  position: relative;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  margin: 5px 0 0 10px;

  .c-menu-item__icon {
    width: 48px;
    height: 48px;
    display: inline-block;
    margin-right: 10px;
    line-height: 40px;
    background-color: var(--background-light);
    font-size: 18px;
  }

  .c-menu-item__data {
    position: relative;
    font-size: 13px;
    margin-left: 0;
    margin-top: -4px;
    color: var(--medium-ui-element);
    width: 250px;
    line-height: 19px;

    .name {
      font-size: 15px;
      //font-weight: 700;
      font-weight: normal;
      color: var(--strong-ui-element);
      display: block;
      overflow: hidden;
      white-space: nowrap;
      width: calc(100% - 70px);

      .up & {
        font-weight: $font-weight-bold;
        color: var(--strong-ui-element);
      }
      .full-width & {
        width: calc(100% - 38px);
        text-overflow: ellipsis;
      }
    }
    .title {
      max-width: calc(100% - 30px);
      text-overflow: ellipsis;
      overflow: hidden;
      float: left;
    }
    .last-message {
      display: block;
      position: relative;
      @include ellipsis(250px);
    }
    .last-message--subject {
      color: var(--strong-ui-element);
    }
    .last-message--snippet {
      color: var(--medium-ui-element);
    }
  }
  time {
    position: absolute;
    right: 13px;
    top: 8px;
    color: var(--medium-ui-element);
    text-align: right;
    // transition: opacity .3s ease-out;
    padding-left: 10px;
    font-size: 12px;

    .up & {
      color: var(--strong-ui-element);
    }
  }
  &:hover {
    background-color: var(--chat-bubble);
  }
  &.selected {
    background-color: #373e6b;
    box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.1);
  }
  .c-menu-item__name--small {
    font-size: 12px;
    font-weight: 400;
  }

  .unsent {
    position: absolute;
    font-size: 12px;
    right: 13px;
    top: 5px;
    color: var(--medium-ui-element);
    text-align: right;
    padding-left: 10px;
    line-height: 17px;

    & svg-comp {
      width: 21px;
      height: 17px;
    }
  }
}

.c-menu-item__title {
  padding: 0px 13px;
  margin: 0px 5px;
  border-radius: 4px;
  cursor: default;
  word-break: break-all;
  background-color: var(--chat-bubble) 44;
}

.c-menu-item {
  .file-name {
    color: var(--strong-ui-element);
  }

  &.selected {
    .time-ago,
    .file-size,
    .file-owner,
    .file-name {
      color: var(--background-light);
    }
  }

  &.up {
    .time-ago {
      color: var(--medium-ui-element);
    }
  }

  &.up.selected {
    .time-ago {
      color: var(--background-light);
    }
  }
  &.search {
    .c-menu-item__data {
      margin-top: 5px;
    }
  }
}
