:root {
  --primary-color-dark: #291d47;
  --primary-color-light: #3a295c;
  --menu-text-color-light: #c1b4f880;
  --menu-text-color: #c1b4f8;
  --menu-text-color-highlight: #ffffff;
  --background-light: #ffffff;
  --background-dark: #faf9fb;
  --medium-ui-element: #9d9ba0;
  --strong-ui-element: #313032;
  --strong-ui-element-rgb: 49, 48, 50;
  --light-ui-element: #efeef1;
  --unread-color: #54c8e8;
  --unread-color-background: #54c8e850;
  --highlight-color: #41247c;
  --chat-bubble-me: #8164bb;
  --chat-bubble-light: #e2e2e266;
  --chat-bubble: #e2e2e2;
  --unread-color-80: #54c8e8bb;
  --background-dark-overlay: #faf9fb90;
  --light-overlay: #ffffff80;
  --animation-speed: 0.2s;
  --box-shadow: 0 2px 6px 0 #0000000a, 0 4px 12px 0 #0000001f;
  --box-shadow-left: -8px 0px 5px -4px #0000001f;
  --brand-color: #5640b2;

  --success-color: #10aa40;
  --success-color-light: #18a845;
  --danger-color: #e9483a;
  --danger-color-light: #e55144;
  --warning-color: #f2830b;
  --warning-color-light: #f58c1a;
}
