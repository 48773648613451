.bold {
  font-weight: 700;
}
.semi-bold {
  font-weight: 500;
}
.extra-small {
  font-size: 10px;
}
.small {
  font-size: 12px;
}
