.h-100 {
  height: 100%;
}

.pb-0 {
  padding-bottom: 0 !important;
}
.padding-bottom {
  padding-bottom: var(--theme-spacing, 16px);
}
.padding-top {
  padding-top: var(--theme-spacing, 16px);
}
.padding-right {
  padding-right: var(--theme-spacing, 16px);
}
.padding-left {
  padding-left: var(--theme-spacing, 16px);
}
.padding-vertical {
  @extend .padding-bottom;
  @extend .padding-top;
}
.padding-horizontal {
  @extend .padding-right;
  @extend .padding-left;
}
.padding {
  @extend .padding-vertical;
  @extend .padding-horizontal;
}

.padding-bottom-half {
  padding-bottom: var(--theme-spacing-half, 8px);
}
.padding-top-half {
  padding-top: var(--theme-spacing-half, 8px);
}
.padding-right-half {
  padding-right: var(--theme-spacing-half, 8px);
}
.padding-left-half {
  padding-left: var(--theme-spacing-half, 8px);
}
.padding-left-quarter {
  padding-left: var(--theme-spacing-quarter, 4px);
}
.padding-right-quarter {
  padding-right: var(--theme-spacing-quarter, 4px);
}
.padding-top-quarter {
  padding-top: var(--theme-spacing-quarter, 4px);
}
.padding-bottom-quarter {
  padding-bottom: var(--theme-spacing-quarter, 4px);
}
.padding-vertical-half {
  @extend .padding-bottom-half;
  @extend .padding-top-half;
}
.padding-horizontal-half {
  @extend .padding-right-half;
  @extend .padding-left-half;
}
.padding-horizontal-quarter {
  @extend .padding-right-quarter;
  @extend .padding-left-quarter;
}
.padding-vertical-quarter {
  @extend .padding-top-quarter;
  @extend .padding-bottom-quarter;
}
.padding-half {
  @extend .padding-vertical-half;
  @extend .padding-horizontal-half;
}
.padding-quarter {
  @extend .padding-vertical-quarter;
  @extend .padding-horizontal-quarter;
}

.m-0 {
  margin: 0;
}

.margin-bottom-half {
  margin-bottom: var(--theme-spacing-half, 8px);
}
.margin-top-half {
  margin-top: var(--theme-spacing-half, 8px);
}
.margin-right-half {
  margin-right: var(--theme-spacing-half, 8px);
}
.margin-left-half {
  margin-left: var(--theme-spacing-half, 8px);
}
.margin-right-quarter {
  margin-right: var(--theme-spacing-quarter, 4px);
}
.margin-left-quarter {
  margin-left: var(--theme-spacing-quarter, 4px);
}
.margin-top-quarter {
  margin-top: var(--theme-spacing-quarter, 4px);
}
.margin-bottom-quarter {
  margin-bottom: var(--theme-spacing-quarter, 4px);
}
.margin-vertical-half {
  @extend .margin-bottom-half;
  @extend .margin-top-half;
}
.margin-horizontal-half {
  @extend .margin-right-half;
  @extend .margin-left-half;
}
.margin-horizontal-quarter {
  @extend .margin-right-quarter;
  @extend .margin-left-quarter;
}
.margin-vertical-quarter {
  @extend .margin-top-quarter;
  @extend .margin-bottom-quarter;
}
.margin-half {
  @extend .margin-vertical-half;
  @extend .margin-horizontal-half;
}

.margin-quarter {
  @extend .margin-vertical-quarter;
  @extend .margin-horizontal-quarter;
}

.gap {
  gap: var(--theme-spacing, 16px);
}
.gap-x {
  column-gap: var(--theme-spacing, 16px);
}
.gap-y {
  row-gap: var(--theme-spacing, 16px);
}

.gap-half {
  gap: var(--theme-spacing-half, 8px);
}
.gap-quarter {
  gap: var(--theme-spacing-quarter, 4px);
}
.gap-x-half {
  column-gap: var(--theme-spacing-half, 8px);
}
.gap-x-quarter {
  column-gap: var(--theme-spacing-quarter, 4px);
}
.gap-y-half {
  row-gap: var(--theme-spacing-half, 8px);
}

.margin-bottom {
  margin-bottom: var(--theme-spacing, 16px);
}
.margin-top {
  margin-top: var(--theme-spacing, 16px);
}
.margin-right {
  margin-right: var(--theme-spacing, 16px);
}
.margin-left {
  margin-left: var(--theme-spacing, 16px);
}
.margin-vertical {
  @extend .margin-bottom;
  @extend .margin-top;
}
.margin-horizontal {
  @extend .margin-right;
  @extend .margin-left;
}
.margin {
  @extend .margin-vertical;
  @extend .margin-horizontal;
}
.cursor-pointer {
  cursor: pointer;
}
.flex {
  display: flex;
}
.flex-row {
  flex-direction: row;
}
.flex-col {
  flex-direction: column;
}
.flex-col-reverse {
  flex-direction: column-reverse;
}
.flex-row-reverse {
  flex-direction: row-reverse;
}
.inline-flex {
  display: inline-flex;
}
.\!inline-flex {
  display: inline-flex !important;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-none {
  flex: none;
}
.flex-wrap {
  flex-wrap: wrap;
}
.justify-start {
  justify-content: flex-start;
}
.justify-center {
  justify-content: center;
}
.justify-end {
  justify-content: flex-end;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.items-start {
  align-items: flex-start;
}
.items-center {
  align-items: center;
}
.items-end {
  align-items: flex-end;
}
.gap {
  gap: var(--theme-spacing, 16px);
}
.gap-x {
  column-gap: var(--theme-spacing, 16px);
}
.gap-y {
  row-gap: var(--theme-spacing, 16px);
}

.gap-half {
  gap: var(--theme-spacing-half, 8px);
}
.gap-x-half {
  column-gap: var(--theme-spacing-half, 8px);
}
.gap-x-quarter {
  column-gap: var(--theme-spacing-quarter, 4px);
}
.gap-y-half {
  row-gap: var(--theme-spacing-half, 8px);
}
.bold {
  font-weight: var(--font-weight-bold);
}
.overflow-auto {
  overflow: auto;
}
.overflow-auto-y {
  overflow-y: auto;
}
.overflow-hidden {
  overflow: hidden;
}

.text-overflow {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.whitespace-no-wrap {
  white-space: nowrap;
}
.whitespace-pre-wrap {
  white-space: pre-wrap;
}

.form-layout {
  display: flex;
  flex-direction: column;
  gap: var(--theme-spacing);
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
}
.underline {
  text-decoration: underline;
}
.block {
  display: block;
}
.contents {
  display: contents;
}
.d-none {
  display: none;
}
.visibility-hidden {
  visibility: hidden;
}
.min-width-0 {
  min-width: 0;
}
.hide-empty:empty {
  display: none;
}
.w-100 {
  width: 100%;
}

.border-radius-normal {
  border-radius: var(--theme-border-radius);
}

.horizontal-divider {
  border-top: var(--border-small) var(--theme-delimiter-color);
}

.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
