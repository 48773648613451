.detail-title {
  height: 30px;
  justify-content: center;
  padding-right: 150px;
  flex-direction: column;
  text-align: center;
  padding-left: 150px;
  flex: 0 0;
  @media (max-width: 1340px) {
    &.simple {
      padding-left: 100px;
      padding-right: 100px;
    }
  }

  .title {
    white-space: nowrap;
    width: 50%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin: 7px auto 0 auto;
    font-size: 18px;
    line-height: 1.2;
    text-align: center;
    color: var(--medium-ui-element);
    font-weight: 700;
  }
  .subtitle {
    white-space: nowrap;
    width: 70%;
    text-overflow: ellipsis;
    overflow: hidden;
    display: block;
    margin: 0 auto;
    font-size: 12px;
    font-weight: $font-weight-normal;
    color: var(--medium-ui-element);
  }
}
.detail-title.simple {
  min-height: 50px;

  &::after {
    border-bottom: 1px solid var(--chat-bubble);
    content: '';
  }

  .title {
    line-height: 50px;
    margin: auto;
  }
}
