.checkbox-toggler {
  position: relative;
  height: 28px;
  width: 54px;
  border-radius: 20px;
  background-color: var(--medium-ui-element);
  transition: background-color 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &.checked {
    background-color: var(--unread-color);
  }

  svg-comp {
    color: var(--background-light);
    fill: var(--background-light);
    position: absolute;
    left: 0px;
    top: 0px;
    width: 28px;
    height: 28px;

    &:nth-child(2) {
      left: auto;
      right: 0px;
    }
  }

  label {
    position: absolute;
    display: block;
    width: 54px;
    height: 28px;
    left: 0;
    top: 0;

    span {
      display: block;
      position: absolute;
      top: 2px;
      left: 2px;
      width: 24px;
      height: 24px;
      border-radius: 20px;
      background-color: var(--background-light);
      transition: all 0.4s ease;
    }
  }
  input[type='checkbox'] {
    visibility: hidden;
  }

  &.checked {
    input[type='checkbox'] + label span {
      left: 28px;
    }
  }
}
