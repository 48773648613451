@import './xbbcode';

.clickable-icon {
  color: var(--medium-ui-element);
  padding: var(--theme-spacing-quarter);
  cursor: pointer;
  border-radius: var(--border-radius-normal);

  &:hover {
    background-color: var(--light-ui-element);
    color: var(--strong-ui-element);
  }

  &.selected {
    background-color: var(--light-ui-element);
    color: var(--strong-ui-element);
  }
}

.loop-mention-overlay {
  background: var(--background-light);
  border-radius: var(--border-radius-large);
  box-shadow: 0 2px 5px 0 var(--medium-ui-element);
}

.loop-custom-dropdown-overlay {
  background: var(--background-light);
  border-radius: var(--border-radius-normal);
  box-shadow: var(--box-shadow);
}

.dropdown-header {
  padding: var(--theme-spacing-half) var(--theme-spacing);
  border-bottom: var(--border-small) var(--light-ui-element);
  display: flex;
  align-items: center;
  font-size: var(--font-size-new-default);
  font-weight: 400;
}

.parsed-email {
  color: var(--unread-color-80);
  text-decoration: underline;
}

.tag,
.parsed-url {
  color: var(--unread-color);
}

a.parsed-url {
  color: var(--unread-color);
  text-decoration: underline;

  &:visited {
    color: var(--unread-color);
    text-decoration: underline;
  }
}

.loop-signature {
  user-select: none !important;
  -webkit-user-modify: read-only !important;
  pointer-events: none !important;
  -webkit-user-select: none !important;
}

.img-broken-thumbnail {
  background-color: var(--light-ui-element);
}
