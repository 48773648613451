@import '../reference';

$avatar-size: 20px;

.avatar-size-150 {
  &.squared {
    border-radius: 10px !important;
  }
  width: 150px;
  height: 150px;
  border-radius: 100%;
  line-height: 100px;
  font-size: 30px;
  display: inline-block;
}

.avatar-extra-large {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  line-height: 100px;
  font-size: 30px;
  display: inline-block;
}

.avatar-big {
  width: 48px;
  height: 48px;
  border-radius: 100%;
}
.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.avatar-small {
  width: $avatar-size;
  height: $avatar-size;
  border-radius: 50%;
}

// Avatar placeholder fallback
img.avatar:after {
  line-height: 30px;
  font-size: 50%;
}
img.avatar-big:after {
  line-height: $avatar-size * 2;
  font-size: $avatar-size;
}
img.avatar-small:after {
  line-height: $avatar-size;
  font-size: $avatar-size * 0.5;
}
img.avatar,
img.avatar-big,
img.avatar-small {
  position: relative;
}
img.avatar:before,
img.avatar-big:before,
img.avatar-small:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
img.avatar:after,
img.avatar-big:after,
img.avatar-small:after {
  content: attr(data-name-initials);
  text-transform: uppercase;
  text-align: center;
  color: var(--background-light);
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #2ecf83;
}
