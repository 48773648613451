.chat-wrapper,
.reply-text {
  .tag {
    color: var(--unread-color);
  }
}

.comment-wrapper {
  overflow: hidden;

  .chat-bubble span,
  .comment span {
    white-space: pre-wrap;

    &.system {
      color: var(--medium-ui-element);

      .tag {
        border-radius: 6px;
        padding: 1px 3px;
        background-color: var(--unread-color-background);
      }
    }
    .tag {
      color: var(--unread-color);
    }
    &:empty {
      display: none;
    }
  }

  .comment-actions-active & {
    overflow: visible;
  }

  .file {
    height: 280px;
    width: 280px;
    position: relative;

    .file-preview {
      width: 100%;
      height: 100%;
    }
  }

  &.me {
    padding-right: 42px;
    padding-left: unset;
    margin-left: 0px !important;
    margin-right: 18px;

    .unsent {
      margin-right: 5px;
    }

    .chat-avatar {
      right: 0px;
      left: unset;
      cursor: pointer;
    }

    time-ago {
      margin-right: 6px;
    }

    .post-author-info {
      display: flex;
      flex-direction: row-reverse;
      margin-right: 27px;
      align-items: center;
    }

    .body {
      justify-content: flex-end;

      .file {
        &.isLast {
          .file-preview {
            border-bottom-right-radius: 20px;

            .hover-cover {
              border-bottom-right-radius: 20px;
            }
          }
        }

        .file-preview {
          border-top-right-radius: 4px;
          border-bottom-right-radius: 4px;
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
          border: solid 1px var(--medium-ui-element);
          overflow: hidden;

          .hover-cover {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
          }
        }
      }
    }
  }

  .body {
    display: flex !important;
    flex-direction: column;

    .file {
      &:has(.attachment-video) {
        .file-preview {
          border-radius: 4px;
        }
      }

      &.isLast {
        .file-preview {
          border-bottom-left-radius: 20px !important;

          .hover-cover {
            border-bottom-left-radius: 20px !important;
          }
        }
      }

      .file-preview {
        border: solid 1px var(--medium-ui-element) !important;
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 20px;
        border-top-right-radius: 20px;
        overflow: hidden;

        .hover-cover {
          border-top-left-radius: 4px;
          border-bottom-left-radius: 4px;
          border-bottom-right-radius: 20px;
          border-top-right-radius: 20px;
        }
      }
    }

    &.hasQuote {
      .chat-bubble:not(.quoted-comment) {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
      }
    }
  }

  .bubble-wrapper {
    max-width: 75%;
    margin-right: 25%;
    display: flex;
    flex-direction: column;
    width: fit-content;

    &.me {
      margin-right: unset;
      margin-left: 25%;
      align-self: flex-end;
    }
  }

  .comment {
    user-select: text !important;
    border-radius: 20px;
    padding: 6px 14px;
    display: inline-block;
    background-color: var(--chat-bubble);
    position: relative;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    align-self: flex-start;
    width: 100%;

    &.isNotBubble {
      padding: 0px;
      background-color: unset !important;
    }

    &.isSystemMessage {
      background-color: transparent;
      padding: 0px;
      letter-spacing: 0.5px;
    }

    &.isMessageDeleted {
      background-color: transparent;
      border: 1px solid var(--medium-ui-element);

      span {
        color: var(--medium-ui-element);
      }
    }

    a {
      color: var(--unread-color);
      text-decoration: underline;
    }

    i.edited-text {
      opacity: 0.65;
      white-space: nowrap;
      user-select: text;
    }

    &.me {
      border-top-left-radius: 20px;
      border-top-right-radius: 4px;
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 4px;
      background-color: var(--chat-bubble-me);

      i.edited-text {
        color: var(--menu-text-color-highlight);
      }

      span {
        color: var(--menu-text-color-highlight);

        .tag {
          color: var(--unread-color);
        }

        &.system {
          * {
            color: var(--medium-ui-element);
          }

          a {
            color: var(--unread-color);
            text-decoration: underline;
          }
        }

        a {
          color: var(--unread-color);
          text-decoration: underline;
        }
      }

      &.isSystemMessage {
        margin-top: 5px;
        background-color: transparent;

        span {
          .tag {
            border-radius: 6px;
            padding: 1px 3px;
            color: var(--unread-color) !important;
            background-color: var(--unread-color-background);
          }
        }
      }

      &.isMessageDeleted {
        background-color: transparent;
        border: 1px solid var(--medium-ui-element);

        span {
          color: var(--medium-ui-element);
        }
      }

      &.isLast {
        border-bottom-right-radius: 20px;
      }
    }

    &.isLast {
      border-bottom-left-radius: 20px;
    }

    &.quoted-comment {
      background: var(--chat-bubble-light);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      padding: 6px 14px;
      font-size: 13px;

      &.deleted-quoted-comment {
        background: transparent;
        border: 1px solid var(--medium-ui-element);
      }

      i.edited-text,
      span {
        color: var(--medium-ui-element);
      }

      .quote-author {
        display: flex;

        svg-comp {
          height: 14px;
          width: 14px;
          color: var(--medium-ui-element);
          margin-right: 4px;
        }

        > span {
          display: block;
          line-height: 1.18;
          font-weight: 600;
        }
      }

      .quote-file-snippet {
        margin-bottom: 4px;
        color: var(--medium-ui-element);
      }

      .quoted-image {
        margin-bottom: 2px;

        img {
          max-width: 124px;
          max-height: 124px;
          display: block;
          border-radius: 4px;
          cursor: pointer;
        }
      }
    }
  }

  .emoji:not(.hasQuote) {
    .comment {
      background-color: transparent !important;
    }
  }
}

.comment-wrapper {
  user-select: text;
  a {
    color: var(--unread-color);
  }
}

.comment-wrapper span a {
  user-select: text !important;
  text-decoration: underline;
}

.comment-wrapper .body {
  .xbbcode-b {
    font-weight: 700;
  }

  .xbbcode-i {
    font-style: italic;
  }

  .xbbcode-u {
    text-decoration: underline;
  }

  .xbbcode-center {
    text-align: center;
    width: 100%;
    display: inline-block;
  }

  .xbbcode-s {
    text-decoration: line-through;
  }
}

// Comment actions
.body {
  &:hover {
    .comment-actions {
      display: inline-flex;
    }
  }

  .me & {
    .comment-actions {
      flex-direction: row-reverse;
      right: unset;
      left: 0;
      transform: translateX(-100%);
    }
  }

  .comment-actions {
    display: none;
    align-items: center;
    padding: 0 7px;
    position: absolute;
    right: 0;
    transform: translateX(100%);
    top: 0;
    bottom: 0;
    z-index: 10;
    pointer-events: none;

    &.active {
      display: inline-flex;
    }

    .comment-actions-active & {
      display: none;

      &.active {
        display: inline-flex;
      }
    }

    emoji-picker {
      margin: auto 3px;
      pointer-events: all;

      .emoji-picker {
        margin: 0;
      }
    }

    .action {
      margin: auto 3px;
      display: flex;
      align-items: center;
      cursor: pointer;
      pointer-events: all;

      &:hover {
        svg-comp {
          color: var(--strong-ui-element);
        }
      }

      svg-comp {
        color: var(--medium-ui-element);
        width: 26px;
        height: 26px;
        border-radius: 4px;
        padding: 4px;
      }
    }
  }
}
