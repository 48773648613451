@import '../reference';

.a-hide {
  display: none !important;
}

.hidden {
  display: none;
  visibility: hidden;
}

.hide {
  display: none;
}

.a-rotate-180 {
  @include rotate(180deg);
}

.right {
  float: right;
}
.left {
  float: left;
}
.clear {
  clear: both;
}
.block {
  display: block;
}
.lowercase {
  text-transform: lowercase;
}
.breakable {
  word-wrap: break-word;
}
.pacified {
  opacity: 0.5;
  pointer-events: none;
  cursor: default;
}
.disable-click {
  pointer-events: none;
}

.hide-text {
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
}
