.bg-light-ui-element {
  background-color: var(--light-ui-element);
}

.hover\:bg-light-ui-element:hover {
  background-color: var(--light-ui-element);
}

.text-unread-color {
  color: var(--unread-color);
}

.text-medium {
  color: var(--medium-ui-element);
}
