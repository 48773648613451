.svg-icon {
  width: 30px;
  height: 30px;
  display: inline-block;
  vertical-align: middle;
}

.ignore-pointer-event {
  pointer-events: none;
}

svg-comp {
  display: inline-block;
  svg {
    display: block;
    height: 100%;
    width: 100%;
  }
}
