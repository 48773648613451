.fr-popup.fr-desktop {
  z-index: 1001 !important; // overlay has z-index of 1000
}
.fr-toolbar .fr-btn-grp {
  margin: 0; // Prevent margin of toolbar being to the middle
}

// Selected More paragraph style
.fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
  background: var(--light-ui-element);
}

// Dropdowns
.fr-command.fr-btn.fr-dropdown.fr-active + .fr-dropdown-menu {
  overflow-y: scroll;
  overflow-x: hidden;
  display: inline-block;
  background: var(--background-dark);
  color: var(--strong-ui-element);
}

.fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper,
.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
  height: auto;
  max-height: 165px !important;
}

#fontSize-3 {
  span {
    width: unset !important;
    color: var(--strong-ui-element);
  }
}
[id^='fontFamily-'] {
  span {
    color: var(--strong-ui-element);
  }
}

[id^='fontSize-'] {
  span {
    width: unset !important;
    color: var(--strong-ui-element);
  }
}

.fr-toolbar .fr-more-toolbar.fr-expanded {
  height: 34px;
}

.fr-box.fr-basic .fr-element {
  line-height: 1.4;
  color: var(--strong-ui-element);
  -webkit-user-select: auto;

  * {
    -webkit-user-select: auto;
  }
}

.froala-new {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;

  .fr-wrapper {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
  }
}

.froala-new,
.froala-styles,
.toolbar {
  display: flex;
  flex-direction: column;

  .fr-newline {
    display: none;
  }

  .fr-wrapper,
  .fr-toolbar {
    border: unset !important;
    border-radius: var(--theme-border-radius);
    background-color: unset !important;
  }

  &.disabled {
    .fr-wrapper,
    .fr-toolbar {
      cursor: not-allowed;
      opacity: 0.6;
    }
  }

  &:not(.disabled) {
    &.focused {
      border: var(--border-small) var(--unread-color);
      border-radius: var(--theme-border-radius);
    }
  }

  .fr-second-toolbar {
    display: none !important;
  }

  .fr-toolbar .fr-command.fr-btn svg path,
  .fr-popup .fr-command.fr-btn svg path {
    fill: var(--medium-ui-element);
  }

  .fr-toolbar .fr-more-toolbar {
    background-color: var(--background-dark);
  }

  .fr-wrapper {
    border-radius: 0 0 var(--theme-border-radius) var(--theme-border-radius);
    -moz-border-radius: 0 0 var(--theme-border-radius) var(--theme-border-radius);
    -webkit-border-radius: 0 0 var(--theme-border-radius) var(--theme-border-radius);
    word-break: break-word;
    z-index: 0;
  }

  .fr-toolbar.fr-top {
    border-radius: var(--theme-border-radius) var(--theme-border-radius) 0 0;
    -moz-border-radius: var(--theme-border-radius) var(--theme-border-radius) 0 0;
    -webkit-border-radius: var(--theme-border-radius) var(--theme-border-radius) 0 0;
  }

  .fr-more-toolbar.fr-expanded .fr-command.fr-btn {
    &:after {
      top: 16px;
      //right: -4px;
    }
  }

  .fr-toolbar .fr-command.fr-btn {
    margin-top: 0;
    margin-bottom: 0;
    height: 32px;
    z-index: unset;

    svg {
      margin: 4px;
    }
    &:after {
      top: 13px;
    }
  }

  .fr-element.fr-view {
    padding: 0;
    flex: 1;
  }

  .fr-newline {
    margin: 0;
  }

  a,
  a:visited {
    color: var(--unread-color);
    text-decoration: underline;
    cursor: pointer;
  }

  .original-content {
    font-size: var(--font-size-new-default);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans',
      'Helvetica Neue', sans-serif;
  }
}

//.deprecated-froala {
//  .clearfix::after {
//    clear: both;
//    display: block;
//    content: '';
//    height: 0;
//  }
//
//  .hide-by-clipping {
//    position: absolute;
//    width: 1px;
//    height: 1px;
//    padding: 0;
//    margin: -1px;
//    overflow: hidden;
//    clip: rect(0, 0, 0, 0);
//    border: 0;
//  }
//
//  img.fr-rounded,
//  .fr-img-caption.fr-rounded img {
//    border-radius: 10px;
//    -moz-border-radius: 10px;
//    -webkit-border-radius: 10px;
//    -moz-background-clip: padding;
//    -webkit-background-clip: padding-box;
//    background-clip: padding-box;
//  }
//
//  img.fr-bordered,
//  .fr-img-caption.fr-bordered img {
//    border: solid 5px #ccc;
//  }
//
//  img.fr-bordered {
//    -webkit-box-sizing: content-box;
//    -moz-box-sizing: content-box;
//    box-sizing: content-box;
//  }
//
//  .fr-img-caption.fr-bordered img {
//    -webkit-box-sizing: border-box;
//    -moz-box-sizing: border-box;
//    box-sizing: border-box;
//  }
//
//  img.fr-shadow,
//  .fr-img-caption.fr-shadow img {
//    -webkit-box-shadow:
//      0 1px 3px rgba(0, 0, 0, 0.12),
//      0 1px 1px 1px rgba(0, 0, 0, 0.16);
//    -moz-box-shadow:
//      0 1px 3px rgba(0, 0, 0, 0.12),
//      0 1px 1px 1px rgba(0, 0, 0, 0.16);
//    box-shadow:
//      0 1px 3px rgba(0, 0, 0, 0.12),
//      0 1px 1px 1px rgba(0, 0, 0, 0.16);
//  }
//
//  .fr-view span[style~='color:'] a {
//    color: inherit;
//  }
//
//  .fr-view strong {
//    font-weight: 700;
//  }
//
//  .fr-view table {
//    border: none;
//    border-collapse: collapse;
//    empty-cells: show;
//    max-width: 100%;
//  }
//
//  .fr-view table td {
//    min-width: 5px;
//  }
//
//  .fr-view table td.hide-borders {
//    min-width: 5px;
//    border: none !important;
//  }
//
//  .fr-view table.fr-dashed-borders td,
//  .fr-view table.fr-dashed-borders th {
//    border-style: dashed;
//  }
//
//  .fr-view table.fr-alternate-rows tbody tr:nth-child(2n) {
//    background: #f5f5f5;
//  }
//
//  .fr-view table td:empty,
//  .fr-view table th:empty {
//    height: 20px;
//  }
//
//  .fr-view table td.fr-highlighted,
//  .fr-view table th.fr-highlighted {
//    border: 1px double red;
//  }
//
//  .fr-view table td.fr-thick,
//  .fr-view table th.fr-thick {
//    border-width: 2px;
//  }
//
//  .fr-view table th {
//    background: #e6e6e6;
//  }
//
//  .fr-view hr {
//    clear: both;
//    user-select: none;
//    -o-user-select: none;
//    -moz-user-select: none;
//    -khtml-user-select: none;
//    -webkit-user-select: none;
//    -ms-user-select: none;
//    page-break-after: always;
//  }
//
//  .fr-view .fr-file {
//    position: relative;
//  }
//
//  .fr-view .fr-file::after {
//    position: relative;
//    content: '\1F4CE';
//    font-weight: normal;
//  }
//
//  .fr-view pre {
//    white-space: pre-wrap;
//    word-wrap: break-word;
//    overflow: visible;
//  }
//
//  .fr-view[dir='rtl'] blockquote {
//    border-left: none;
//    border-right: solid 2px #5e35b1;
//    margin-right: 0;
//    padding-right: 5px;
//    padding-left: 0px;
//  }
//
//  .fr-view[dir='rtl'] blockquote blockquote {
//    border-color: #00bcd4;
//  }
//
//  .fr-view[dir='rtl'] blockquote blockquote blockquote {
//    border-color: #43a047;
//  }
//
//  .fr-view blockquote {
//    border-left: solid 2px #5e35b1;
//    margin-left: 0;
//    padding-left: 5px;
//    color: #5e35b1;
//  }
//
//  .fr-view blockquote blockquote {
//    border-color: #00bcd4;
//    color: #00bcd4;
//  }
//
//  .fr-view blockquote blockquote blockquote {
//    border-color: #43a047;
//    color: #43a047;
//  }
//
//  .fr-view span.fr-emoticon {
//    font-weight: normal;
//    font-family: 'Apple Color Emoji', 'Segoe UI Emoji', 'NotoColorEmoji', 'Segoe UI Symbol', 'Android Emoji',
//      'EmojiSymbols';
//    display: inline;
//    line-height: 0;
//  }
//
//  .fr-view span.fr-emoticon.fr-emoticon-img {
//    background-repeat: no-repeat !important;
//    font-size: inherit;
//    height: 1em;
//    width: 1em;
//    min-height: 20px;
//    min-width: 20px;
//    display: inline-block;
//    margin: -0.1em 0.1em 0.1em;
//    line-height: 1;
//    vertical-align: middle;
//  }
//
//  .fr-view .fr-text-gray {
//    color: #aaa !important;
//  }
//
//  .fr-view .fr-text-bordered {
//    border-top: solid 1px #222;
//    border-bottom: solid 1px #222;
//    padding: 10px 0;
//  }
//
//  .fr-view .fr-text-spaced {
//    letter-spacing: 1px;
//  }
//
//  .fr-view .fr-text-uppercase {
//    text-transform: uppercase;
//  }
//
//  .fr-view img {
//    position: relative;
//    max-width: none;
//  }
//
//  .fr-view img.fr-dib {
//    margin: 5px auto;
//    display: block;
//    float: none;
//    vertical-align: top;
//  }
//
//  .fr-view img.fr-dib.fr-fil {
//    margin-left: 0;
//    text-align: left;
//  }
//
//  .fr-view img.fr-dib.fr-fir {
//    margin-right: 0;
//    text-align: right;
//  }
//
//  .fr-view img.fr-dii {
//    display: inline-block;
//    float: none;
//    vertical-align: bottom;
//    margin-left: 5px;
//    margin-right: 5px;
//    max-width: calc(100% - (2 * 5px));
//  }
//
//  .fr-view img.fr-dii.fr-fil {
//    float: left;
//    margin: 5px 5px 5px 0;
//    max-width: calc(100% - 5px);
//  }
//
//  .fr-view img.fr-dii.fr-fir {
//    float: right;
//    margin: 5px 0 5px 5px;
//    max-width: calc(100% - 5px);
//  }
//
//  .fr-view span.fr-img-caption {
//    position: relative;
//    max-width: 100%;
//  }
//
//  .fr-view span.fr-img-caption.fr-dib {
//    margin: 5px auto;
//    display: block;
//    float: none;
//    vertical-align: top;
//  }
//
//  .fr-view span.fr-img-caption.fr-dib.fr-fil {
//    margin-left: 0;
//    text-align: left;
//  }
//
//  .fr-view span.fr-img-caption.fr-dib.fr-fir {
//    margin-right: 0;
//    text-align: right;
//  }
//
//  .fr-view span.fr-img-caption.fr-dii {
//    display: inline-block;
//    float: none;
//    vertical-align: bottom;
//    margin-left: 5px;
//    margin-right: 5px;
//    max-width: calc(100% - (2 * 5px));
//  }
//
//  .fr-view span.fr-img-caption.fr-dii.fr-fil {
//    float: left;
//    margin: 5px 5px 5px 0;
//    max-width: calc(100% - 5px);
//  }
//
//  .fr-view span.fr-img-caption.fr-dii.fr-fir {
//    float: right;
//    margin: 5px 0 5px 5px;
//    max-width: calc(100% - 5px);
//  }
//
//  .fr-view .fr-video {
//    text-align: center;
//    position: relative;
//  }
//
//  .fr-view .fr-video > * {
//    -webkit-box-sizing: content-box;
//    -moz-box-sizing: content-box;
//    box-sizing: content-box;
//    max-width: 100%;
//    border: none;
//  }
//
//  .fr-view .fr-video.fr-dvb {
//    display: block;
//    clear: both;
//  }
//
//  .fr-view .fr-video.fr-dvb.fr-fvl {
//    text-align: left;
//  }
//
//  .fr-view .fr-video.fr-dvb.fr-fvr {
//    text-align: right;
//  }
//
//  .fr-view .fr-video.fr-dvi {
//    display: inline-block;
//  }
//
//  .fr-view .fr-video.fr-dvi.fr-fvl {
//    float: left;
//  }
//
//  .fr-view .fr-video.fr-dvi.fr-fvr {
//    float: right;
//  }
//
//  .fr-view a.fr-strong {
//    font-weight: 700;
//  }
//
//  .fr-view a.fr-green {
//    color: green;
//  }
//
//  .fr-view .fr-img-caption {
//    text-align: center;
//  }
//
//  .fr-view .fr-img-caption .fr-img-wrap {
//    padding: 0px;
//    display: inline-block;
//    margin: auto;
//    text-align: center;
//    width: 100%;
//  }
//
//  .fr-view .fr-img-caption .fr-img-wrap img {
//    display: block;
//    margin: auto;
//    width: 100%;
//  }
//
//  .fr-view .fr-img-caption .fr-img-wrap > span {
//    margin: auto;
//    display: block;
//    padding: 5px 5px 10px;
//    font-size: 14px;
//    font-weight: initial;
//    -webkit-box-sizing: border-box;
//    -moz-box-sizing: border-box;
//    box-sizing: border-box;
//    -webkit-opacity: 0.9;
//    -moz-opacity: 0.9;
//    opacity: 0.9;
//    -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)';
//    width: 100%;
//    text-align: center;
//  }
//
//  .fr-view button.fr-rounded,
//  .fr-view input.fr-rounded,
//  .fr-view textarea.fr-rounded {
//    border-radius: 10px;
//    -moz-border-radius: 10px;
//    -webkit-border-radius: 10px;
//    -moz-background-clip: padding;
//    -webkit-background-clip: padding-box;
//    background-clip: padding-box;
//  }
//
//  .fr-view button.fr-large,
//  .fr-view input.fr-large,
//  .fr-view textarea.fr-large {
//    font-size: 24px;
//  }
//
//  .fr-popup .fr-layer.fr-color-hex-layer {
//    display: none;
//  }
//
//  .fr-popup .fr-color-set.fr-selected-set {
//    padding: 10px !important;
//  }
//
//  /**
//     * Image style.
//     */
//  a.fr-view.fr-strong {
//    font-weight: 700;
//  }
//
//  a.fr-view.fr-green {
//    color: green;
//  }
//
//  /**
//     * Link style.
//     */
//
//  img.fr-view {
//    position: relative;
//  }
//
//  img.fr-view.fr-dib {
//    margin: 5px auto;
//    display: block;
//    float: none;
//    vertical-align: top;
//  }
//
//  img.fr-view.fr-dib.fr-fil {
//    margin-left: 0;
//    text-align: left;
//  }
//
//  img.fr-view.fr-dib.fr-fir {
//    margin-right: 0;
//    text-align: right;
//  }
//
//  img.fr-view.fr-dii {
//    display: inline-block;
//    float: none;
//    vertical-align: bottom;
//    margin-left: 5px;
//    margin-right: 5px;
//    max-width: calc(100% - (2 * 5px));
//  }
//
//  img.fr-view.fr-dii.fr-fil {
//    float: left;
//    margin: 5px 5px 5px 0;
//    max-width: calc(100% - 5px);
//  }
//
//  img.fr-view.fr-dii.fr-fir {
//    float: right;
//    margin: 5px 0 5px 5px;
//    max-width: calc(100% - 5px);
//  }
//
//  .no-borders {
//    border: none !important;
//  }
//
//  .show-borders {
//    border: 1px solid #ddd !important;
//  }
//
//  span.fr-img-caption.fr-view {
//    position: relative;
//    max-width: 100%;
//  }
//
//  span.fr-img-caption.fr-view.fr-dib {
//    margin: 5px auto;
//    display: block;
//    float: none;
//    vertical-align: top;
//  }
//
//  span.fr-img-caption.fr-view.fr-dib.fr-fil {
//    margin-left: 0;
//    text-align: left;
//  }
//
//  span.fr-img-caption.fr-view.fr-dib.fr-fir {
//    margin-right: 0;
//    text-align: right;
//  }
//
//  span.fr-img-caption.fr-view.fr-dii {
//    display: inline-block;
//    float: none;
//    vertical-align: bottom;
//    margin-left: 5px;
//    margin-right: 5px;
//    max-width: calc(100% - (2 * 5px));
//  }
//
//  span.fr-img-caption.fr-view.fr-dii.fr-fil {
//    float: left;
//    margin: 5px 5px 5px 0;
//    max-width: calc(100% - 5px);
//  }
//
//  span.fr-img-caption.fr-view.fr-dii.fr-fir {
//    float: right;
//    margin: 5px 0 5px 5px;
//    max-width: calc(100% - 5px);
//  }
//
//  .fr-element ul,
//  .fr-element ol {
//    margin-top: 0px;
//  }
//
//  .fr-element img {
//    padding: 0px;
//  }
//
//  button[data-cmd='fontSize'] > span {
//    float: none !important;
//    margin: auto !important;
//    margin-left: 5px !important;
//    margin-right: 8px !important;
//    width: auto !important;
//  }
//
//  .reply .fr-box.fr-basic .fr-element {
//    padding: 13px 0px;
//    margin: 0;
//    height: 100%;
//  }
//
//  .fr-box.fr-basic .fr-element {
//    line-height: 1.4;
//    color: var(--strong-ui-element);
//    padding: 20px 20px 0;
//    -webkit-user-select: auto;
//
//    * {
//      -webkit-user-select: auto;
//    }
//  }
//
//  .fr-box.fr-basic .fr-element.force-light-bg {
//    line-height: 1.4;
//    color: #414141 !important;
//  }
//
//  .fr-box.fr-basic .fr-wrapper {
//    word-break: break-word;
//    background: var(--background-light);
//  }
//
//  .fr-box.fr-basic .fr-wrapper.force-light-bg {
//    background: #fff !important;
//  }
//
//  /**
//     *  Theme overrides
//     */
//  #fontFamily-1 {
//    color: var(--strong-ui-element);
//  }
//
.fr-toolbar .fr-command.fr-btn.fr-open:not(:hover):not(:focus):not(:active) {
  background: var(--light-ui-element);
}

.fr-desktop .fr-command:not(.fr-table-cell) .fr-open,
.fr-desktop .fr-command:not(.fr-table-cell):hover {
  background: var(--light-ui-element);
}

.fr-toolbar .fr-command.fr-btn svg path,
.fr-popup .fr-command.fr-btn svg path {
  fill: var(--medium-ui-element);
}

.fr-toolbar .fr-command.fr-btn:hover svg path,
.fr-popup .fr-command.fr-btn:hover svg path {
  fill: var(--medium-ui-element);
  background: none !important;
}

.fr-toolbar .fr-more-toolbar {
  background-color: var(--background-dark);
}

//  .fr-element h1 {
//    color: var(--medium-ui-element) !important;
//    font-size: 26px;
//    font-weight: 700;
//    border-bottom: none !important;
//    text-align: left !important;
//    padding: 10px 0px !important;
//    margin: 0px;
//    line-height: initial;
//  }
//
//  .fr-element h2 {
//    color: var(--medium-ui-element) !important;
//    font-size: 22px !important;
//    font-weight: 700;
//    border-bottom: none !important;
//    text-align: left !important;
//    padding: 10px 0px !important;
//    margin: 0px;
//  }
//
//  .fr-element h3 {
//    color: var(--medium-ui-element);
//    font-size: 18px;
//    font-weight: 700;
//    padding: 6px 0px;
//    margin: 0px;
//  }
//
//  .fr-element h4 {
//    color: var(--medium-ui-element);
//    font-size: 14px;
//    padding: 4px 0px;
//    margin: 0px;
//  }
//
//  .fr-element h5 {
//    color: var(--medium-ui-element);
//    font-size: 12px;
//    padding: 4px 0px;
//    margin: 0px;
//  }
//
//  .fr-element h6 {
//    color: var(--medium-ui-element);
//    font-size: 10px;
//    padding: 0px;
//    padding: 4px 0px;
//    margin: 0px;
//  }
//
//  .fr-view .original-content table td,
//  .fr-view .original-content table th {
//    border: none;
//  }
//
//  .fr-view blockquote {
//    border-left: none !important;
//    border: none !important;
//    padding: 0px !important;
//  }
//
//  .fr-element ol {
//    list-style-type: decimal;
//    margin: 0px 0px 0px 40px;
//  }
//
//  .fr-element ul {
//    list-style-type: disc;
//    margin: 0px 0px 0px 40px;
//  }
//
//  .fr-toolbar .fr-newline {
//    margin-left: 0px;
//    margin-right: 0px;
//    background: var(--light-ui-element);
//
//    .composer & {
//      margin-left: -30px;
//    }
//
//    .new-comment & {
//      margin-left: -20px;
//      margin-right: -20px;
//    }
//  }
//
//  .fr-element a {
//    color: var(--unread-color);
//  }
//
//  .fr-element a:hover {
//    text-decoration: underline;
//  }
//
//  .fr-toolbar .fr-btn-grp {
//    border-right: 1px solid var(--light-ui-element);
//    border-top: none;
//    border-bottom: none;
//    border-left: none;
//    margin: 0;
//  }
//
//  .fr-command.fr-btn.fr-dropdown + .fr-dropdown-menu {
//    display: none;
//  }
//
//
.fr-popup {
  background: var(--background-dark);
  color: var(--strong-ui-element);
}

.fr-popup .fr-input-line input[type='text'],
.fr-popup .fr-input-line input + label {
  background-color: var(--background-light);
  color: var(--strong-ui-element);
}

.fr-toolbar .fr-command.fr-btn.fr-btn-active-popup {
  background: var(--light-ui-element);
}

.fr-wrapper .fr-placeholder {
  color: var(--medium-ui-element);
}

.fr-desktop .fr-command:hover:not(.fr-table-cell),
.fr-desktop .fr-command:focus:not(.fr-table-cell),
.fr-desktop .fr-command.fr-btn-hover:not(.fr-table-cell),
.fr-desktop .fr-command.fr-expanded:not(.fr-table-cell) {
  background: var(--light-ui-element);
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown:after,
.fr-popup .fr-command.fr-btn.fr-dropdown:after,
.fr-modal .fr-command.fr-btn.fr-dropdown:after {
  border-top-color: var(--strong-ui-element);
}

.fr-toolbar .fr-command.fr-btn,
.fr-popup .fr-command.fr-btn,
.fr-modal .fr-command.fr-btn {
  border-top-color: var(--strong-ui-element);
  color: var(--strong-ui-element);
}

.fr-popup .fr-buttons.fr-tabs {
  background: var(--light-ui-element);
}

.fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content ul.fr-dropdown-list li a.fr-active {
  background: var(--light-ui-element);
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active,
.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active {
  background: var(--light-ui-element);
}

.fr-toolbar .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-popup .fr-command.fr-btn.fr-dropdown.fr-active:hover,
.fr-modal .fr-command.fr-btn.fr-dropdown.fr-active:hover {
  background: var(--light-ui-element);
}

.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-toolbar .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-popup .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active,
.fr-modal .fr-tabs .fr-command.fr-btn:not(:hover):not(:focus).fr-active-tab {
  background: var(--background-dark);
}
//
//  .new-canned-response {
//    .fa-text-height {
//      width: 30px !important;
//    }
//  }
//
//  .fr-toolbar {
//    border-top: none;
//    box-shadow: none;
//    -webkit-box-shadow: none;
//    background: none;
//    min-width: 200px;
//    width: auto;
//    display: flex;
//    z-index: 0;
//  }
//
//  .fr-toolbar.fr-top {
//    box-shadow: none !important;
//    border: none;
//    -webkit-box-shadow: none !important;
//    display: flex;
//    margin-left: 30px;
//
//    .new-comment & {
//      margin-left: 0;
//    }
//  }
//
//  .fr-toolbar.fr-bottom {
//    box-shadow: none !important;
//    -webkit-box-shadow: none !important;
//  }
//
//  .fr-wrapper {
//    box-shadow: none !important;
//    border: none !important;
//    -webkit-box-shadow: none !important;
//    height: 100%;
//    overflow: auto;
//    width: 100%;
//    z-index: 1 !important;
//    flex: 1;
//  }
//
//  .fr-box {
//    height: 100%;
//  }
//
//  .fr-box .loop-signature-to-post {
//    margin-top: 20px;
//  }
//
//  .fr-element.fr-view {
//    height: 100%;
//    min-height: 100%;
//  }
//
//  .inline-toolbar {
//    .fr-toolbar {
//      margin-left: 0 !important;
//    }
//  }
//
//  .toolbar {
//    .fr-toolbar .fr-command.fr-btn {
//      width: auto;
//      flex-grow: 1;
//
//      & i {
//        margin: auto;
//      }
//    }
//  }
//
//  .fr-second-toolbar {
//    display: none !important;
//  }
//
//  @media (max-width: 1340px) {
//    .fr-toolbar .fr-command.fr-btn {
//      width: auto;
//      flex-grow: 1;
//
//      & i {
//        margin: auto;
//      }
//
//      svg.fr-svg {
//        margin: 8px 2px;
//      }
//    }
//  }
//
//  @media (max-width: 1340px) {
//    .signature-modal,
//    .new-canned-response {
//      .fr-toolbar .fr-command.fr-btn {
//        width: auto;
//        flex-grow: 1;
//
//        & i {
//          margin: auto;
//        }
//
//        svg.fr-svg {
//          margin: 8px 7px;
//        }
//      }
//    }
//  }
//
//  .fr-toolbar .fr-btn-grp:last-of-type {
//    margin: 0;
//    border: none;
//  }
//
//  .fr-toolbar .fr-btn-grp #fontSize-1 span {
//    width: 40px !important;
//  }
//
//  .fr-command.fr-btn.fr-active + .fr-dropdown-menu .fr-dropdown-wrapper,
//  .fr-command.fr-btn + .fr-dropdown-menu .fr-dropdown-wrapper .fr-dropdown-content {
//    height: auto;
//    max-height: 165px !important;
//  }
//
//  .fr-box.fr-basic .fr-element {
//    padding-bottom: 0px;
//  }
//
//  .fr-toolbar .fr-command.fr-btn,
//  .fr-popup .fr-command.fr-btn {
//    path {
//      fill: var(--medium-ui-element) !important;
//    }
//  }
//
//  .fr-toolbar .fr-command.fr-btn.fr-active,
//  .fr-popup .fr-command.fr-btn.fr-active {
//    svg {
//      path {
//        fill: var(--unread-color) !important;
//      }
//    }
//  }
//
//  .fr-toolbar .fr-command.fr-btn.fr-active,
//  .fr-popup .fr-command.fr-btn.fr-active {
//    color: var(--medium-ui-element) !important;
//    background: none !important;
//  }
//
//  .fr-popup.fr-desktop {
//    display: none;
//  }
//
//  .fr-toolbar {
//    .fr-popup.fr-desktop.fr-active {
//      display: block;
//    }
//  }
//
//  .froala-toolbar-wrapper {
//    width: 100%;
//  }
//
//  .c-menu-item.search .c-menu-item__icon {
//    width: 36px;
//    height: 36px;
//    padding: 0px;
//    border: none;
//  }
//
//  .file-modal video {
//    width: auto !important;
//    min-height: 230px !important;
//    height: auto !important;
//    max-width: 100% !important;
//  }
//
//  .fr-element [contenteditable='false'] {
//    pointer-events: none;
//    user-select: none;
//
//    * {
//      user-select: none;
//    }
//
//    table {
//      border: none;
//    }
//  }
//
//  .fr-box.fr-basic .fr-element {
//    overflow-x: visible;
//  }
//
//  .fr-element.fr-view {
//    user-select: inherit;
//
//    p {
//      user-select: inherit;
//    }
//  }
//
//  .fr-view img {
//    max-width: none;
//
//    &.inserted-image {
//      max-width: 100%;
//    }
//  }
//
//  .fr-view {
//    .loop-signature-to-post,
//    .loop-signature-to-post * {
//      user-select: none !important;
//      -webkit-user-modify: read-only !important;
//      pointer-events: none !important;
//
//      table td {
//        border: none !important;
//      }
//    }
//  }
//
//  .create-siganture-modal .fr-view table td {
//    border: none;
//  }
//
//  .fr-placeholder {
//    color: var(--medium-ui-element) !important;
//  }
//
//  .fr-view table:not(.inserted-table) td,
//  .fr-view table:not(.inserted-table) th {
//    &:not(.fr-selected-cell) {
//      border: 1px solid #ddd;
//    }
//  }
//}
//
//.fr-popup {
//  z-index: 501 !important;
//
//  button:after {
//    border-top-color: var(--medium-ui-element) !important;
//  }
//
//  svg {
//    path {
//      fill: var(--medium-ui-element) !important;
//    }
//  }
//}
