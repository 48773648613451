body {
  color: var(--strong-ui-element);
  height: 100%;
  overflow: hidden;
}

/*
 * Position has to be fixed because of the white bar that we get on '.scrollIntoView(true)' on windows
 * Reference issue: https://github.com/4thOffice/brisket/issues/6885
 */
html {
  position: fixed;
  height: 100%;
  width: 100%;
}
