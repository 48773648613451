// Buttons
button {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

.dropdown-button-green {
  transition: var(--animation-speed);
  width: 220px;
  height: 28px;
  margin: 0 auto;
  text-align: center;
  background-color: #5aba62;
  border-radius: 14px;
  color: var(--background-light);
  line-height: 27px;
  font-size: 13px;
  font-weight: 600;
  cursor: pointer;
  &:hover {
    background-color: #369b3d;
  }
}
