@import '../reference';

// Dropdowns

.dropdown {
  position: relative;
  display: block;

  .dropdown-menu {
    background: var(--background-light);
    box-shadow: var(--box-shadow);
    cursor: default;
    color: var(--strong-ui-element);
    border-radius: 8px;
    padding: 6px 0;
    margin: 6px 10px;
    max-height: 240px;
    width: 230px;
    right: -30px;
    top: 25px;
    position: absolute;
    visibility: hidden;
    z-index: 20;

    &:before {
      background-color: var(--background-light);
      content: '';
      display: block;
      height: 18px;
      width: 18px;
      position: absolute;
      z-index: -1;
      transform: rotate(-45deg);
      top: -8px;
      box-shadow: 2px -2px 2px 0 var(--light-ui-element);
      right: 22px;
    }

    &.small {
      width: 180px;
      top: 20px !important;
      right: -21px !important;
      &:before {
        right: 15px;
      }
      a {
        padding: 5px 10px;
        font-size: 12px;
      }
    }

    &.left-aligned {
      right: auto !important;
      left: -30px !important;
      &:before {
        left: 15px;
        right: auto;
      }
      &.ipc-adjusted {
        left: -16px !important;
        &:before {
          left: 20px;
        }
      }
      &.reply-files {
        left: -28px !important;
        &:before {
          left: 20px;
        }
      }
      &.reply-signature {
        left: -8px !important;
        bottom: 35px !important;

        &.in-composer {
          left: -16px !important;
        }

        ul {
          position: relative;
          max-height: 170px;
        }
      }
    }
    &.above {
      top: auto !important;
      bottom: 35px !important;

      &:before {
        top: auto;
        bottom: -8px;
        box-shadow: -2px 2px 2px 0 var(--medium-ui-element);
      }
    }
  }

  &.open .dropdown-menu {
    display: block;
    visibility: visible;
    animation: bounceInDown 100ms;
    // opacity: 1 !important;
    // transform: translate3d(0px,0px,0px) scaleY(1) !important;
    // transition: all 40ms cubic-bezier(.17,.67,1,.53);
    opacity: 1;
  }

  li a,
  .dropdown-item {
    display: block;
    padding: 5px 18px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    cursor: pointer;
    text-align: left;

    &:hover {
      background: var(--light-ui-element);
    }
    &.active {
      border-bottom: solid 1px var(--light-ui-element);
    }
    &.reminder {
      background-color: #fafafa;
    }
  }
  input[type='file'] {
    display: none;
  }
  svg-comp {
    width: 20px;
    height: 20px;
    display: inline-block;
    vertical-align: middle;
  }
  label {
    position: relative;
    overflow: hidden;
    display: block;
    cursor: pointer;
  }
  .collapsable-item {
    padding: 5px 18px;
    white-space: nowrap;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 24px;
    color: var(--medium-ui-element);
    vertical-align: middle;
    cursor: pointer;
    font-size: 14px;
    svg-comp {
      width: 20px;
      height: 20px;
      margin-right: 10px;
      margin-top: -4px;
      overflow: hidden;
    }
    &:not(.isOnLoopInPopup):not(.on-assign-dropdown) {
      &:hover {
        color: var(--medium-ui-element);
        background: var(--light-ui-element);
      }
    }
  }
}
