// TODO: Should move whole middle-item here

drafts-list .middle-item.selected,
.folders-list a.c-menu-item:focus,
.middle-item.selected:focus {
  position: relative;
  box-shadow: var(--box-shadow);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background-color: var(--unread-color-80) !important;
    opacity: 0.25;
    pointer-events: none;
  }

  .snippet-body {
    color: var(--strong-ui-element) !important;
  }
}
